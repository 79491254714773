// Main Content
.page-container {
  .page-main {
    background:$light;
    padding:25px;
    padding-top:60px;
    padding-bottom:60px;
    line-height:1.9;
  }
}

// Sidebar

.page-container {
  .page-sidebar {
    background:darken($light,15%);
    padding-top:60px;
    padding-bottom:60px;

    .sidebar-carrier {
      margin-top:40px;
      text-align:center;
      img {
        max-width:100%;
      }
    }
  }
}
.sidebar-contact {
  .sb-contact-title {
    background:$brand-secondary;
    color:white;
    text-align:center;
    padding:20px 5px;
    font-weight:700;
    font-size:24px;
    font-family:$heading-font;
    text-transform:uppercase;
  }

  .sb-contact-form {
    background: transparent;
    padding:20px;
    background:white;
    border-left:3px solid darken($light,45%);
    border-right:3px solid darken($light,45%);
    border-bottom:3px solid darken($light,45%);
    border-bottom-right-radius:3px;
    border-bottom-left-radius:3px;

    label {
      display:none !important;
    }

    input, textarea {
        font-weight: 700;
        font-size: 16px;
        color: $dark;
        width: 100%;
        background: transparent;
        text-align: left;
        padding: .75em .65em .75em 0;
        border-right: 0;
        border-top: 0;
        border-left: 0;
        border-bottom: 2px solid darken($light,65%);
        margin: 0 0 0 0;
        transition: all 0.25s ease-in-out;
        max-height: 175px;
        margin-bottom:20px;
    }
    .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
        font-size: 16px;
        font-family: inherit;
        padding: .75em .65em .75em 0;
        letter-spacing: normal;
    }
    input[type="submit"] {
      background:$brand-secondary;
      color:white;
      border:none;
      padding:15px 25px;
      border-radius:3px;
      transition-duration:350ms;
      -webkit-transition-duration:350ms;
      -moz-transition-duration:350ms;
      &:hover {
        background:lighten($brand-secondary,5%);
        cursor:pointer;
      }
    }
  }
}
