.single-location {
  .location-col {
    //margin-bottom:2rem;
  }
  // global
  .single-location-info {
    .single-location-info-title {
      background:$brand-primary;
      color:white;
      padding:15px;
    }
    .single-location-info-content {
      padding:30px 15px;
      border:1px solid $light;
      background:white;
      border-top:none;
      word-wrap:break-word;
      p {
        margin:0;
        padding:0;
        i,svg {
          color:$brand-primary;
        }
        .content-icon {
          padding-bottom:1rem;
          padding-right:10px;
        }
        .content-text {
          padding-bottom:1rem;
        }
        &.info-address {
          a {
            font-weight:400;
            color:inherit;
            &:hover {
              text-decoration:underline;
            }
          }
        }
        &.info-phone {
          a[href^=tel] {
            font-weight:400;
            color:$brand-primary;
          }
        }
        &.info-email {
          a[href^=mailto] {
            font-weight:400;
            color:inherit;
            &:hover {
              text-decoration:underline;
            }
          }
        }
        &:last-of-type {
          .content-icon, .content-text {
            padding-bottom:0;
          }
        }
      }
    }
  }
  // core information
  .core-information-col {
    .single-location-info-content {
      display:table;
      border-collapse: separate;
      border-spacing: 0 0 1rem 0;
      width:100%;
      p {
        display:table-row;
        .content-icon {
          display:table-cell;
          vertical-align:middle;
          padding-bottom:1rem;
          padding-right:10px;
        }
        .content-text {
          display:table-cell;
          vertical-align:middle;
          padding-bottom:1rem;
        }
      }
    }
  }
  // hours of operation
  .location-hours {
    .location-hours-table {
      .location-hours-row {
        align-items:center;
        justify-content: space-between;
        margin-bottom:1rem;
      }
    }
  }
  // social media
  .location-social-media {
    .location-social-single {
      display:flex;
      align-items:center;
      justify-content:center;
      text-align:center;
      padding-top:1rem;
      padding-bottom:1rem;
      a {
        color:$brand-primary;
        font-weight:400;
        i,svg {
          font-size:250%;
          width:100%;
          margin-bottom:5px;
        }
        &:hover {
          color:$dark;
        }
      }
    }
  }

  // reviews
  .location-reviews {
    .location-reviews-slider {
      .location-single-review {
        .location-single-review-icon {
          font-size:50px;
          margin-bottom:1rem;
          opacity:0.15;
        }
        .location-single-review-content {
          font-style:italic;
          margin-bottom:1rem;
          padding-left:40px;
          padding-right:40px;
        }
        .location-single-review-name {
          span {
            padding:10px 30px;
            border-radius:999px;
            text-transform:uppercase;
            color:white;
          }
        }
      }
    }
  }

}
