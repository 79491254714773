@media (max-width: 1799px) {
	h1.h4,h1.h4 small, h3.h5, h5.secondary, h2.h3 {
	   display: none;
	}
}

h1.h4 {
  font-size:1.1rem;
  background:#f2f2f2;
  padding:10px;
  text-align:center;
}

h2.h3 {
	font-size:1.1rem;
  background:#f2f2f2;
  padding:10px;
  text-align:center;
	font-weight:500;
}

h3.h5 {
  font-size:1.1rem;
  background:#c0c0c0;
  color:#000;
  padding:10px;
  text-align:center;
}

.main h1.h4 small:after {
	content: " and surrounding communities";
}

.hidden {
	display:none !important;
}

.bodyimgright, .container img.bodyimgright, .sidebar img.bodyimgright, .plugin-img {
	float: right;
	max-width: 30%;
}

.seo {
	display: none;
}
@media (min-width:1800px) {
	.seo {
		display: block;
	}
}
