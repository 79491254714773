.hero-bkg-slider, .absolute-slider {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #101010;

    .slick-list, .slick-track, .slick-slide {
        width:100%;
        height: 100%;
			* {
				height: 100%;
			}
            div {
                @include media-breakpoint-down(lg) {
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(27, 74, 151, .5);
                    }
                }
            }
    }
}
.screen-padding {
    padding-top: 10vh;
    padding-bottom: 10vh;
}
/** embed video styles**/
.hero-video {
    overflow: hidden!important;

    .autoplay-video {
        position: absolute;
        left: 50%;
        bottom: 0%;
        -webkit-transform: translate(-50%, 0%) !important;
        -o-transform: translate(-50%, 0%) !important;
        transform: translate(-50%, 0%) !important;
        width: auto;
        min-width: 100%;
        min-height: 100%;
    }
}

/** youtube video styles **/
.inline-YTPlayer{
    top: 50%!important;
    transform: translateY(-50%)!important;
}

.hero-bkg-youtube-inner {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
}

.hp-hero .h2 {
	font-size: 2.25em;
	@include media-breakpoint-up(md){
		font-size: 3em;
	}
}
.hp-hero .h3 {
	font-size: 1.5em;
	@include media-breakpoint-up(md){
		font-size: 2em;
	}
}
