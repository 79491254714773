body {
  background:#fff;
}

.main *::selection {
	color: $white;
	background: $brand-primary;
}

// Safari Phone Link Fix

a[href^=tel] {
   text-decoration:inherit;
   color: inherit;
}

/** custom classes */
.text-center-md , .text-center-md * {
	@include media-breakpoint-down(md){
		text-align: center!important;
	}
}
.border-radius {
	border-radius: 15px;
}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.min-height {
	min-height: 50vw;
	@include media-breakpoint-up(md) {
		min-height: 33vw;
	}
	@include media-breakpoint-up(lg) {
		min-height: 350px;
	}
}
.full-height {
    height: 100%;
}
.square {
    padding-top: 100%;
}
.overflow-hidden {
    overflow: hidden!important;
}
.absolute {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100%;
}
.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
.flex-wrap {
	display: flex;
	flex-wrap: wrap;
}
.relative {
    position: relative;
    width: 100%;
}
.light-box-shadow {
    box-shadow: 0px 20px 20px -10px rgba(0, 0, 0, 0.25);
}
.timeline::after {
    content: '';
    position: absolute;
    width: 8px;
    border-right: double 8px $brand-primary;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;

    @include media-breakpoint-down(sm) {
        display: none;
    }
}
.plot-left:before {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    left: -11px;
    background-color: white;
    border: 5px double $accent;
    top: 50%;
    border-radius: 50%;
    z-index: 1;

    @include media-breakpoint-down(sm) {
        display: none;
    }
}
.plot-right:before {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -14px;
    background-color: white;
    border: 5px double $accent;
    top: 50%;
    border-radius: 50%;
    z-index: 1;

    @include media-breakpoint-down(sm) {
        display: none;
    }
}
.min-height-smaller {
    min-height: 33vh;

    @include media-breakpoint-up(md) {
        min-height: 300px;
    }
}
.min-height-md-down {
    @include media-breakpoint-down(md) {
        min-height: 400px;
    }
}
.pad-top-100 {
    padding-top: 100%!important;
}
.circle {
    padding: 0px;
    border-radius: 1000px;
    z-index: 2;
    border: solid 5px $brand-primary;
}
.large-icon * {
	font-size: 2.5em;
	@include media-breakpoint-up(md) {
		font-size: 3em;
	}
	@include media-breakpoint-up(md) {
		font-size: 4em;
	}
}
.grow-bkg {
	transition: all .2s ease-in;

	.grow-this-bkg, &.grow-this-bkg {
		background-size: 100%!important;
		transition: all .2s ease-in;

		&:hover{
			transition: all .2s ease-in;
			background-size: 110%!important;
		}
	}
}
.circle {
    padding: 0px;
    border-radius: 1000px;
    z-index: 2;
    border: solid 5px $brand-primary;

    &.small-bio {
        padding: 30px;
        border: solid 5px #fff;
    }
}

.notunder1500 {
	display: none;
}
@media (min-width:1800px) {
	.notunder1500 {
		display: block;
	}
}
.negative-top {
	@include media-breakpoint-up(lg) {
		margin-top: -100px;
	}
}
.negative-top-header {
		margin-top: -47px;
	@include media-breakpoint-up(md) {
		margin-top: -115px;
	}
	@include media-breakpoint-up(lg) {
		margin-top: -130px;
	}
}
.clip-top {
    clip-path: polygon(0px 25px, 100% 0, 100% 100%, 0px 100%);
}
.clip-oblique {
	clip-path:polygon(0px 0px, 100% 3%, 100% 97%, 0px 100%);
	@include media-breakpoint-up(lg){
		clip-path:polygon(0px 0px, 100% 8%, 100% 92%, 0px 100%);
	}
}
.border-bottom-trans-white {
	border-bottom: solid 1px rgba(255,255,255,.1);
}
.box-shadow {
	box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
}
.box-shadow-small {
	box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
}

// Images
.img-wrap {
	img {
		max-height: 75px;
		margin: 10px;
	}
}

img {
	max-width: 100%;
}

.img-on-dark-bg {
	filter: brightness(0) invert(1);
}

// Util

.absolute-link {
  position: absolute;
	z-index: 10;
	top: 0;
	left: 0;
  width: 100%;
  height: 100%;
}

.absolute-positioner {
	top: 0;
	left: 0;
}

.opacity-soft {
	opacity: 0.97;
}

.opacity-75 {
	opacity: 0.75;
}

.opacity-85 {
	opacity: 0.85;
}

.half-opacity {
	opacity: 0.5;
}

.grayscale {
	filter: grayscale(1);
}

.canvas {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(45deg, $dark, darken($dark, 10%));
	opacity: 0.85;

	&.light-fade {
		background: linear-gradient(45deg, #000, lighten(#000,10%), #000);
		opacity: 0.6;
	}
}

.sticky {
	@include media-breakpoint-up(lg) {
		position: sticky !important;
		top: 130px !important;
	}
}

// Content Images

.img-cover-col {
	background-size: cover;
	background-position: center;
	@include media-breakpoint-down(sm) {
		display: none !important;
	}
}

// RESPONSIVE FIXES

.sticky-top {
	@include media-breakpoint-down(sm) {
		position: static;
	}
}

// Default Slick Settings

.slick-arrow {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 99;
	padding: 0px;
	cursor: pointer;
	color:#000;
	height: 40px;
    width: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    background: #fff;

	&.fa-arrow-right, &.fa-chevron-right, &.fa-angle-right, &.arrow-right {
		right: 0px;
		padding-left:5px;
	}
	&.fa-arrow-left, &.fa-chevron-left, &.fa-angle-left, &.arrow-left {
		left: 0px;
		padding-right: 5px;
	}
}

svg.slick-arrow {
	height: 40px!important;
	width: 40px!important;
	padding: 5px;
}


// Custom Slick settings

.vertical-align-slides {
	.slick-initialized .slick-slide {
		@include media-breakpoint-up(md) {
				 float: inherit;
				 display: inline-block !important;
				 vertical-align: middle;
		}
	}
}

.slick-slide img {
	max-width: 175px;
	margin: 0 auto;
}

.card-slider {
	.slick-track
	{
		display: flex !important;
	}

	.slick-slide
	{
		height: inherit !important;

		div {
			height: 100%;
		}
	}
}
// Move accessibility button to bottom
.pojo-a11y-toolbar-toggle {
  bottom:0;
}
