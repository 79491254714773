.woocommerce-cart {
  .elevated-shop-cart {
    padding-top:30px;
    padding-bottom:30px;
    @include media-breakpoint-up(lg) {
      padding-top:60px;
      padding-bottom:60px;
    }

    .shop_table {
      border-radius:1px;
      thead {
        background:$dark;
        color:white;
        border:0;
        th {
          padding-top:15px;
          padding-bottom:15px;
          text-transform:uppercase;
          font-weight:600;
        }
      }

      .input-text {
        height:33px;
        width:250px;
      }

      .button {
        background:white;
        border-radius:1px;
        border:1px solid $brand-primary;
        text-transform:uppercase;
        color:$brand-primary;
        transition-duration:350ms;
        font-size:14px;
        &:hover {
          background:$brand-primary;
          color:white;
        }
      }

      .wcsatt-options {
        padding-left:0;
      }
    }

    .wc-proceed-to-checkout {
      .button {
        border-radius:1px;
        background-color:$brand-primary;
        text-transform:uppercase;
        color:white;
        &:hover {
          background-color:$dark;
        }
      }
    }
  }
}
