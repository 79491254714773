@import "common/variables";

/** Import everything from autoload */
@import "./autoload/_bootstrap.scss";

/**
 * Import npm dependencies
 *
 * Prefix your imports with `~` to grab from node_modules/
 * @see https://github.com/webpack-contrib/sass-loader#imports
 */
// @import "~some-node-module";

@import '~animate.css/animate.min.css';
@import '~aos/dist/aos.css';
@import '~slick-carousel/slick/slick.scss';
@import '~slick-carousel/slick/slick-theme.scss';
//@import '~lightgallery/css/lightgallery-bundle.min.css';

/** Import theme styles */
@import "common/grid";
@import "common/typography";
@import "common/color-classes";
@import "common/global";
@import "common/seo";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/wp-classes";

// Navigation
@import "layouts/header/default";
//@import "layouts/header/centered";
//@import "layouts/header/transparent";
//@import "layouts/header/off-canvas";

////////////////////////////
// Flexible content styles
////////////////////////////

// Hero
@import "internal-page-builder/hero";

////////////////////////////
// Internal Page Builder (COREY)
////////////////////////////

@import "internal-page-builder/internal-page";
@import "internal-page-builder/half-content";

////////////////////////////
// IPB Template Additions (MIKE)
////////////////////////////

@import "internal-page-builder/reviews_style_1";

////////////////////////////
// Other pages (override flexible content settings)
////////////////////////////
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/header";
@import "layouts/posts";
@import "layouts/tinymce";
@import "layouts/homepage";
@import "layouts/coupons";
@import "layouts/location";
//@import "layouts/agegate";

////////////////////////////
// WooCommerce
////////////////////////////

@import "woocommerce/global";
@import "woocommerce/shop-page";
@import "woocommerce/single-product";
@import "woocommerce/cart";
@import "woocommerce/checkout";
