
/********************
TESTIMONIAL SECTION
*******************/

.u-BoxShadow40 {
  -webkit-box-shadow: 0 0 40px rgba(0,0,0,.1);
  box-shadow: 0 0 40px rgba(0,0,0,.1);
}
.u-PaddingBottom100 {
  padding-bottom: 100px;
}
.u-PaddingTop100 {
  padding-top: 100px;
}

/*******
TITLE AREA
********/
.Heading-wing {
  position: relative;
  display: inline-block;
  text-align: center;
}

.Heading-wing::before {
  left: 0;
  margin-left: -85px;
}

.Heading-wing::after {
  right: 0;
  margin-right: -85px;
}


.Heading-wing::after, .Heading-wing::before {
  position: absolute;
  top: 50%;
  width: 64px;
  height: 2px;
  content: "";
  background-color: #000;
}


.u-LetterSpacing1 {
  letter-spacing: 1px;
}

.u-MarginTop5 {
  margin-top: 5px;
}
.Split {
  display: inline-block;
  width: 40px;
  height: 5px;
  background-color: #000;
}

.Line, .Split--height2 {
  height: 2px;
}

.u-MarginTop70 {
  margin-top: 70px;
}

.u-MarginTop35 {
  margin-top: 35px;
}
/*******
QUOTE BOXES
********/

.u-PaddingLeft20 {
  padding-left: 20px;
}

.u-PaddingRight20 {
  padding-right: 20px;
}

.u-MarginBottom45 {
  margin-bottom: 45px;
}
.u-MarginTop0 {
  margin-top: 0;
}
.u-OverflowVisible {
  overflow: visible;
}

.u-Opacity10 {
  opacity: .1;
}


.u-MarginBottom10 {
  margin-bottom: 10px;
}
.u-LineHeight1 {
  line-height: 1;
}
.u-FontSize50 {
  font-size: 50px;
}

.Blockquote--borderLeft2 {
  border-left: 2px solid $brand-primary;
}

.u-PaddingLeft20 {
  padding-left: 20px;
}

.u-BoxShadow40 {
  -webkit-box-shadow: 0 0 40px rgba(0,0,0,.1);
  box-shadow: 0 0 40px rgba(0,0,0,.1);
}


.u-MarginBottom0 {
  margin-bottom: 0;
}
.text-lg {
  font-size: 18px;
}
.text-italic {
  font-style: italic;
}
.Blockquote {
  position: relative;
  padding: 30px 40px;
}


/*******
REVIEWER IMG
********/

.Thumb--rounded {
  overflow: hidden;
  -webkit-border-radius: 999px;
  border-radius: 999px;
}
.Thumb--image {
  text-align: center;
  background-color: rgba(0,0,0,.05);
}
.Thumb {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 46px;
  padding: 0;
  vertical-align: middle;
}

.Thumb--92px {
  width: 92px;
  height: 92px;
}

.u-PaddingRight25 {
  padding-right: 25px;
}

/*******
REVIEWER NAME
********/

.bg-primary--gradient {
  background: -webkit-gradient(linear,left top,right top,from(#{$brand-primary}),to(#{$brand-primary-offset}));
  background: -webkit-linear-gradient(left,#{$brand-primary},#{$brand-primary-offset});
  background: linear-gradient(90deg,#{$brand-primary},#{$brand-primary-offset});
  background-color: #{$brand-primary};
}


.Blockquote__by {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: -20px;
  padding: 0 40px;
  text-align: center;
  letter-spacing: .04em;
}


.u-PaddingRight10 {
  padding-right: 10px;
}

.u-Weight700 {
  font-weight: 700;
}


.u-BorderRadius6, .u-Rounded {
  overflow: hidden;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

.u-Rounded {
  -webkit-border-radius: 999px;
  border-radius: 999px;
}
.u-Padding10x30 {
  padding: 10px 30px;
}
.u-InlineBlock {
  display: inline-block;
}
.bg-primary {
  color: #fff;
  background-color: #457aff;
}

.u-MarginBottom75 {
  margin-bottom: 75px;
}
/*******
EXPLORE MORE
********/

.btn-shadow.focus, .btn-shadow:focus, .btn-shadow:hover {
  color: #457aff;
  outline: 0;
  -webkit-box-shadow: 0 0 15px rgba(0,0,0,.12);
  box-shadow: 0 0 15px rgba(0,0,0,.12);
}

.btn-shadow {
  -webkit-transition: none 172ms ease-out;
  transition: none 172ms ease-out;
  -webkit-transition-property: color,border-color,background-color,-webkit-box-shadow;
  transition-property: color,border-color,box-shadow,background-color;
  transition-property: color,border-color,box-shadow,background-color,-webkit-box-shadow;
  color: #222;
  border-color: #fff;
  background-color: #fff;
  -webkit-box-shadow: 0 0 50px rgba(0,0,0,.1);
  box-shadow: 0 0 50px rgba(0,0,0,.1);
}
