// Backgrounds
.bkgd-img, .bg-img {
	background-color: $white;
	background-position: 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.white-bkg, .bg-white {
	background: #fff;
}
.trans-white-bkg, .bg-trans-white {
	background: rgba(255,255,255,.75);
}
.trans-black-bkg, .bg-trans-black {
	background: rgba(0,0,0,.75);
}
.primary-bkg, .bg-primary, .bg-brand-1, .primary-background {
    background: $brand-primary;
}
.secondary-bkg, .bg-secondary, .bg-brand-2, .secondary-background {
    background: $brand-secondary;
}
.dark-bkg, .bg-dark {
    background: $dark;
}
.accent-bkg, .bg-accent {
    background: $accent;
}
.grey-bkg, .bg-light, .light-bkg {
	background:$light;
}

.color-primary, .primary-color {
  color:$brand-primary;
}
.light-bkg-negative {
	background: rgba(0,0,0,0.05);
	
    @include media-breakpoint-up(lg) {
        background:linear-gradient(transparent 10%, rgba(0,0,0,0.05)10%, rgba(0,0,0,0.05) 90%, transparent 90%);
    }
}