.btn {
    a {
        color: #fff!important;
    }
    &:hover {
        background: $brand-primary;
    }
}
button {
    cursor: pointer!important;

    &:focus {
        outline: 0;
    }
}
.primary-button, .primary-btn, .btn.btn-primary, .btn.primary-button, .btn.secondary-button .cta.nav-item a, .woocommerce #respond input#submit, .woocommerce a.button, .woocommerce button.button, .woocommerce input.button, input#gform_submit_button_1 {
    display:inline-block;
    padding: 1rem 1.5rem;
    border-radius: 30px;
		background: $brand-primary;
		color:#fff!important;
    line-height: 1;
    font-size:auto;
    text-align: center;
    font-family: $paragraph-font;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .1rem;

    a[href^="tel"] {
        color: white!important;
    }

    .theme-button-icon {
        margin-left: 10px;
        position: absolute;
        transition: all .2s ease-in;
        font-size: 1em;
    }

	&:hover {
		text-decoration:none;
		cursor:pointer;
    background:lighten($brand-primary,10%);

    .theme-button-icon  {
        transform: translateX(5px);
    }
	}
    &.light {
        background: linear-gradient(148deg, #efefef 50%, $light);
        color: #222!important;
        text-shadow: none;
    }
    &.accent {
        background: $accent;
        color: #fff!important;
        text-shadow: 0px;

        i {
            color: #fff!important;
        }

        a[href^="tel"] {
            color: #fff!important;
        }
    }
    &.secondary {
        background: $brand-primary;
        color: #fff!important;
        box-shadow: 0px 0px 10px rgba(0,0,0,0);

        a[href^="tel"] {
            color: #fff!important;
        }
        i {
            color: #fff!important;
        }
    }
    &.dark-btn {
        background: $dark!important;
        color: #fff!important;
        text-shadow: 0px 1px 0px rgba(0,0,0,.5);
    }
    &.white-btn {
        background: #fff;
        color: $brand-primary!important;

        &:hover {
            color: $brand-primary!important;
        }
    }
    &.top-margin {
        margin-top: 15px;
    }
    &.no-arrow {
        padding: .5em 1em .5em 1em!important;

        &:after {
            display: none;
        }
    }
}
.cta.nav-item a {
    padding: 0px 15px;
    margin-top: 8px;

    &:after {
        display: none;
    }
}
.secondary-button, .secondary-btn {
	display:inline-block;
	padding:10px 20px;
	font-size:16px;
	background:$brand-primary;
	color:white;
	text-shadow: 1px 1px 0 darken($brand-primary, 5%);
	text-transform:uppercase;
	border-radius:2px;
	transition-duration:350ms;
	-webkit-transition-duration:350ms;
	-moz-transition-duration:350ms;
	text-decoration:none;
	-webkit-box-shadow: 2px 2px 0 1px darken($brand-primary, 10%);
	box-shadow: 2px 2px 0 1px darken($brand-primary, 10%);
	font-weight:700;

	@include media-breakpoint-up(lg) {
		padding:10px 25px;
		font-size:16px;
	}

	&:hover {
		opacity: .8;
        color: #fff;
		text-decoration:none;
		cursor:pointer;
	}
}

.primary-button, .primary-btn,
.secondary-button, .secondary-btn {
    &.small {
        font-size: 14px!important;
        padding: .75em 1em!important;
        margin-bottom: 0px;

        &:after {
            display: none;
        }
    }
}
.button-shadow {
    box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.5);
}
button.btn.btn-primary.btn-xs[data-target="#secondaryGeos"] {
    display: block;
    width: 100%;
    border: 0;
}
div#secondaryGeos {
	background: $accent;
	text-align: center;
	outline: 0!important;

	h4 {
		display: inline-block;
		margin: 10px;
		color: #fff;
		padding: 5px;
		background: rgba(255,255,255,0.1);
		font-size: 15px;
	}
}
