.footer-brands {
	border-top:1px solid $light;
	padding-top:15px;
	padding-bottom:15px;
	margin-top:30px;

	img {
		max-width:100%;
	}
}

.fp-footer {
	color:white;

	.footer-menu-col {
		.footer-menu-title {
			text-transform:uppercase;
			font-family:$heading-font;
			font-size:24px;
			font-weight:700;
			text-align:center;
			margin-bottom:15px;
		}

		ul {
			list-style-type:none;
			text-align:center;
			margin:0;
			padding:0;
			li {
				a {
                    color: white;
                    text-decoration: none;
                    font-weight: normal;
                    font-size: .8em;
                    padding: 5px;
				}
			}
		}
	}

	.footer-left-col {
		background:$brand-secondary;
		padding-top:50px;
		@include media-breakpoint-down(lg) {
			padding-bottom:50px;
		}
	}

	.footer-right-col {
		background:$brand-secondary;
		padding-top:50px;
		@include media-breakpoint-down(lg) {
			padding-bottom:50px;
		}
	}

	.footer-center-col {
		background:white;
		padding-top:20px;
		.footer-logo {
			text-align:center;
			@include media-breakpoint-down(sm) {
				margin-top:35px;
			}
			img {
				max-width:100%;
			}
		}

		.footer-copyright {
			padding-bottom:30px;
		}

		.footer-quick {
			text-align:center;
			color:$brand-primary;
			padding-top:35px;
			p {
				margin:0;
				padding:0;
				margin-bottom:20px;
			}
			.footer-address {
				margin-bottom:5px;
			}

			.footer-social {
				margin-top:20px;
				margin-bottom:20px;
				.footer-social-link {
					color:$brand-primary;
					font-size:22px;
					padding-right:20px;
					transition-duration:350ms;
					-moz-transition-duration:350ms;
					-webkit-transition-duration:350ms;
					&:hover {
						color:$brand-secondary;
					}
				}
			}

			.footer-menu-title {
				text-transform:uppercase;
				font-family:$heading-font;
				font-size:18px;
				font-weight:700;
				text-align:center;
				margin-bottom:15px;
			}
		}
	}
}
