.cart-contents:before {
    font-family:WooCommerce;
    content: "\e01d";
    font-size:28px;
    margin-top:10px;
    font-style:normal;
    font-weight:400;
    padding-right:5px;
    vertical-align: bottom;
}
.cart-contents:hover {
    text-decoration: none;
}
.cart-contents-count {
    color: #fff;
    background-color: $brand-primary;
    border-radius: 10px;
    padding: 1px 6px;
    line-height: 1;
    vertical-align: top;
}

.woocommerce-message, .woocommerce-info {
  display:flex;
  align-items:center;
  &:before {
    content: "\e015";
    color: $brand-primary;
    position:relative;
    top:auto;
    left:auto;
  }

  .button {
    margin:0!important;
  }
}

.woocommerce-info::before {
    color: $brand-primary;
}

.qty {
}

#add_payment_method #payment ul.payment_methods li, .woocommerce-cart #payment ul.payment_methods li, .woocommerce-checkout #payment ul.payment_methods li {
  line-height:1.2;
}

.woocommerce ul.products li.product .price {
  font-size: 1rem;
}

.woocommerce a.button, .woocommerce .widget_price_filter .price_slider_amount .button {
  display:block;
  padding:10px 15px;
  border-radius:30px;
  background:$brand-primary;
  color:white;
  font-weight:$medium-weight;
  font-size:1rem;
  float:none;
  margin-top:1rem;

  &:hover {
    text-decoration:none;
    cursor:pointer;
    background:lighten($brand-primary, 10%);
    color:white;
  }
}

.woocommerce .widget_price_filter .price_slider_amount {
  text-align: left;
  line-height: 1;
  font-size: 1rem;
}

.basebuild-breadcrumbs {
  color:$dark;
  font-weight:$medium-weight;
  font-size:1rem;
  a {
    font-weight:$medium-weight;
    color:$inactive;
    font-size:1rem;
  }
  .bc-separator {
    color:$inactive;
    padding-left:7px;
    padding-right:7px;
    font-size:0.75rem;
  }
}

.star-rating {
  margin-bottom:1rem;
}
.star-rating span:before,
.star-rating::before,
p.stars a:hover:after,
p.stars a:after {
    color: $brand-primary !important;
}

.woocommerce-review-link {
  visibility: hidden;
}

.woocommerce-review-link .count {
  visibility: initial;
}

.woocommerce-review-link .count:before {
  content: "(";
}

.woocommerce-review-link .count:after {
  content: ")";
}

.woocommerce {
  nav.woocommerce-pagination {
    ul {
      border:0;
      li {
        background:white;
        border-right-color:$light;
        span.current {
          background:$brand-primary;
          color:white;
        }
      }
    }
  }
}
