/*
** font sizes calculated in perfect fourths using https://type-scale.com/
*/

// set type
$menu-font:'Montserrat', Helvetica, Arial, sans-serif;
$heading-font:'Montserrat', Helvetica, Arial, sans-serif;
$subheading-font:'Montserrat', Helvetica, Arial, sans-serif;
$paragraph-font:'Lato', Helvetica, Arial, sans-serif;

// set weights
$light-weight:300;
$normal-weight:400;
$medium-weight:600;
$heavy-weight:700;
$ultra-weight:900;


body {
  font-size: 16px;
  line-height: 1.7rem;
  color: #666;
  font-family: $paragraph-font;
  @include media-breakpoint-up(md) {
    font-size: 1.125rem; // 18px
  }
}
a {
  color: $brand-primary;
  font-weight: 700;
  text-decoration: none;
  transition-duration: 350ms;
  -webkit-transition-duration: 350ms;
  -moz-transition-duration: 350ms;
  &:hover {
    text-decoration: none;
    color: $brand-primary;
    opacity: .8;
  }
}
.bold, strong {
  font-weight: 700;
}
.sub-heading {
  font-size: 1.25rem;
  font-family: $paragraph-font;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  color: $accent;
}
.white {
  color: #fff!important;
}
.text-white-all {
  color: #fff !important;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $heading-font;
  margin-top: 0px;
  margin-bottom: 1rem;
  color: #000;
}
h1, .h1 {
  font-weight: $heavy-weight;
  font-size: 1.777rem;
  @include media-breakpoint-up(lg) {
    font-size: 3.157rem;
  }
}
h2, .h2 {
  font-weight: $medium-weight;
  font-size: 1.777rem;
  @include media-breakpoint-up(lg) {
    font-size: 2.369rem;
  }
}
h3, .h3 {
  font-weight: $medium-weight;
  font-size: 1.333rem;
  @include media-breakpoint-up(lg) {
    font-size: 1.666rem;
  }
}
h4, .h4 {
  font-size:1rem;
  font-weight:$medium-weight;
  @include media-breakpoint-up(lg) {
    font-size: 1.333rem;
  }
}

h5, .h5 {
  font-size:1rem;
  font-weight:$medium-weight;
  @include media-breakpoint-up(lg) {
    font-size: 1.25rem;
  }
}
// Line height
.reduce-line-height {
  line-height: 1 !important;
}
// Colors
.color-white, .white {
  color: $white !important;
}
.accent, .white .accent {
  color: $accent!important;
}
.primary, {
  color: $brand-primary;
}
.secondary {
  color: $brand-secondary;
}
.color-md, .dark {
  color: $dark !important;
}
.color-dark {
  color: $black !important;
}
