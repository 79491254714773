// Modify .container-fluid to offer a little more content padding

.container-fluid {
  @include media-breakpoint-up(md) {
    padding-left:30px;
    padding-right:30px;
  }
  @include media-breakpoint-up(lg) {
    padding-left:80px;
    padding-right:80px;
  }
}

@media screen and (min-width: 1800px) {
  .container, .container-fluid {
      max-width: unset;
      padding-left: 190px;
      padding-right: 190px;
  }
}
