.elevated-shop-checkout {
	padding-top:60px;

	.checkout-coupons {
		.woocommerce-info {
			margin-bottom:0 !important;
			border-top-color:$brand-primary;
		}

		.woocommerce-info::before {
			color:$brand-primary;
		}

		.checkout_coupon {
			margin-top:0;
			border-top:0;
			border-left:1px solid #f7f6f7;
			border-right:1px solid #f7f6f7;
			border-bottom:1px solid #f7f6f7;
			border-radius:1px;
			.input-text {
				height:33px;
			}
			.button {
        background:white;
        border-radius:1px;
        border:1px solid $brand-primary;
        text-transform:uppercase;
        color:$brand-primary;
        transition-duration:350ms;
        font-size:14px;
        &:hover {
          background:$brand-primary;
          color:white;
        }
      }
		}
	}

	.elevated-checkout {
		margin-top:30px;
		margin-bottom:40px;
		input {
			height:40px;
		}

		.input-checkbox {
			height:auto;
		}

		.woocommerce-additional-fields {
			.woocommerce-input-wrapper {
				width:100%;
			}
		}
		.elevated-checkout-row {
			.elevated-checkout-details {
				h3 {
					font-weight:700;
					text-transform:uppercase;
					border-bottom: 1px solid;
					padding-bottom: 15px;
					margin-bottom: 30px;
				}
				.woocommerce-billing-fields__field-wrapper, .woocommerce-shipping-fields {
					 	background: #f7f6f7;
						padding: 15px;
					.form-row-first,.form-row-last {
						width:50%;
						.woocommerce-input-wrapper {
							width:100%;
						}
					}
					.form-row {
						margin-bottom:12px;
					}
					.form-row label {
						width:100%;
						line-height:1;
					}
					.form-control {
						border:1px solid #d2d2d2;
						border-radius:2px;
					}
					.woocommerce-input-wrapper {
						width:100%;
					}
					#billing_address_1_field {
						margin-bottom:5px;
					}
				}
				#ship-to-different-address {
					font-size:18px;
					text-transform:none;
					margin-top:20px;
					padding-bottom:0;
					margin-bottom:0;
					border:none;
					@include media-breakpoint-down(md) {
						margin-bottom:20px;
					}
				}
				.woocommerce-form__label-for-checkbox span {
					padding-left:10px;
				}
				.shipping_address {
					display:none;
				}
			}
		}
		.elevated-checkout-review {
			h3 {
				font-weight:700;
				text-transform:uppercase;
				border-bottom: 1px solid;
				padding-bottom: 15px;
				margin-bottom: 30px;
			}
			.shop_table {
				border-radius:1px;
				border-collapse:collapse;
				thead {
					background:$dark;
					color:white;
					border:0;
				}
			}
			.cart_item, .cart-subtotal, .shipping {
				color:#555;
			}
			.order-total {
				color:$dark;
			}
			#payment {
				background: $dark;
			  color:white;
			}
			.payment_box {
				background-color:white !important;
			}
			.payment_methods {
				border-bottom:1px solid $dark !important;
			}
			.place-order {
				.button {
					background:$brand-primary;
					font-size:38px;
					font-weight:600;
					padding:15px 40px;
					margin:0 auto;
					transition-duration:350ms;
					-moz-transition-duration:350ms;
					-webkit-transition-duration:350ms;
					font-size:22px;
					line-height:26px;
					margin-bottom:16px;
					letter-spacing:3.3px;
					text-transform:uppercase;
					@include media-breakpoint-up(xl) {
							font-size: 41px;
							margin-bottom:24px;
							letter-spacing:6.15px;
							line-height:49px;
					}
					&:hover {
						background:white;
						color:$dark;
					}
				}
			}
			// stripe
			.payment_method_stripe {
				.form-row {
					margin-bottom:0 !important;
					padding:0;
				}
				label[for=payment_method_stripe] {
					font-weight:700;
					text-transform:uppercase;
				}
				label[for=stripe-card-element] {
					width:100%;
					line-height:1;
				}
				.stripe-card-group {
					width:100%;
				}
				label[for=stripe-exp-element] {
					width:100%;
					line-height:1;
				}
				.wc-stripe-elements-field {
					width:100%;
				}
				label[for=stripe-cvc-element] {
					width:100%;
					line-height:1;
				}
				.wc-stripe-elements-field {
					height: 40px;
					padding-top: 11px;
					border:1px solid #d2d2d2;
				}
			}
		}
		.woocommerce-privacy-policy-text {
			p {
				font-size:12px;
				line-height:1;
				text-align:center;
			}
		}
	}
}
