/** Import Bootstrap functions */

@import "~bootstrap/scss/functions";

$slick-font-path: "~slick-carousel/slick/fonts/";
$slick-loader-path: "~slick-carousel/slick/";

// Theme Colors/Branding
$brand-primary: #1459BA;
$brand-primary-rgb: 20,89,186;
$brand-primary-offset: #1459BA;
$brand-secondary: #222;
$dark: #222;
$light: #f5f5f5;
$inactive: #8c8c8c;

// Theme Colors/Branding
$white: #fff;
$accent: #1459BA;
$accent-2: #1459BA;

// Actions
$transition-fast: all 0.15s ease-in;
$transition-med: all 0.3s ease-in;
$transition-slow: all 0.5s ease-in;

$rounded-border: 3px;

$padding-light: 1rem;
$padding-med: 2rem;
$padding-heavy: 3rem;

// Custom Grid/Containers
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
  xxxxl: 1800px
)!default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 1540px,
  xxxxl: 1740px,
)!default;
