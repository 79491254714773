// Style 1
.sticky-top {
  position: sticky;
}
.reveal-phone {
  cursor: pointer;
}
.hidden-phone {
  background: #fff;
  display: none;
  padding: 15px;
  border-radius: 5px;
  position: absolute;
  top: 30px;
  width: 200px;
  z-index: 99999;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  right: 0px;
  text-align: center;
  &:before {
    content: '';
    width: 0px;
    height: 0px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
    position: absolute;
    top: -7px;
    left: 0px;
    right: 0px;
    margin: auto;
    position: absolute;
  }
}
.header-style-1-topbar {
  background: $accent;
  color: white;
  line-height: 20px;
  font-family: $subheading-font;
  padding-top:5px;
  padding-bottom:5px;
  cursor: default!important;
  letter-spacing: .5px;
  font-size: 13px;
  text-transform: uppercase;
  @include media-breakpoint-down(lg) {
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .nav-emergency {
    display: none;
    color: white;
    padding-right: 10px;
    font-size: 18px;
    font-weight: normal!important;
    text-transform: uppercase!important;
    @include media-breakpoint-up(lg) {
      display: inline-block;
    }
  }
  .primary-btn {
    background: $brand-primary-offset;
    color: #fff;
    font-size: 14px;
    letter-spacing: .05em;
    margin-right: 10px;
    text-shadow: none;
    box-shadow: none;
    border: 0px;
    padding: 5px 10px 5px 10px;
    text-transform: none;
    &:after {
      display: none;
    }
  }
  .social-media-link {
    color: #fff;
    padding-right: 10px;
    position: relative;
    display: inline-block;
    margin-top: 0px;
    &:hover {
      color: #fff;
      opacity: .8;
    }
  }
  .phone {
    display: inline-block;
    font-size: 15px;
    margin-left: 15px;
    @include media-breakpoint-up(sm) {
      font-size: 18px;
    }
    .sub-heading {
      color: $accent;
      margin: 0px;
      letter-spacing: normal;
      font-size: 12px;
      display: block;
    }
  }
}
@media (min-width:1500px) {
  /*.header-style-1-topbar {
        background: linear-gradient(60deg, #003c6e 66%, #f03e44 66%)!important;
    }*/
}
.header-style-1-nav {
  background: #fff;
  box-shadow: 0px 7px 15px -15px rgba(0, 0, 0, 0.15);
  padding-top: 0px;
  padding-bottom: 0px;
  z-index: 10001;
  width: 100%;

  @include media-breakpoint-down(lg) {
    padding-left: 15px;
    padding-right: 15px;
  }
  .show .navbar-nav {
    height: calc(100vh - 150px);
    overflow: scroll;
    @include media-breakpoint-down(sm) {
      padding-bottom: 200px!important;
    }
    @include media-breakpoint-up(xl) {
      height: auto;
      padding-bottom: 0px!important;
    }
  }
  .row.header-style-1-contain-row {
    width: calc(100% + 30px);
  }
  .theme-logo {
    max-width: 100%;
    max-height: 70px;
    padding: 5px 15px;
    &:hover {
      opacity: 1;
    }
  }
  li.dropdown:last-child {
    @include media-breakpoint-up(xl) {
      .dropdown-menu {
        right: 0px;
        left: auto;
      }
    }
  }
  .navbar-nav .nav-link {
    color: $brand-primary;
    transition-duration: 350ms;
    text-transform: uppercase;
    font-family: $subheading-font;
    outline: none!important;
    padding: 5px 15px;
    font-weight: $medium-weight;
    &:hover, &:focus {
      opacity: .8;
      color: $brand-primary!important;
    }
    @include media-breakpoint-up(xl) {
      padding-left: .5em;
      padding-right: .5em;
      font-size: 16px;
      letter-spacing: .05em;
    }
    &.dropdown-toggle::after {
      color: $accent!important;
    }
  }
  .navbar-nav .active .nav-link, .navbar-nav .active .nav-link:focus {
    color: $brand-primary;
  }
  .navbar-toggler {
    border: none;
    position: absolute;
    right: 15px;
    top: 20px;
    outline: none!important;
    transition: all .2s ease-in;
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(0,34,68)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }
    @include media-breakpoint-up(md) {
      top: 25px;
    }
  }
  button.navbar-toggler.ml-auto.collapsed {
    outline: none;
  }
  .dropdown.menu-item-has-children {
    @include media-breakpoint-down(lg) {
      a {
        padding-left: 15px;
      }
    }
  }
  .dropdown-menu {
    width: 100%!important;
    border: none;
    margin-top: 0px;
    transform: none!important;
    min-width: 16rem;
    border-radius: 0px;
    background: $dark;
    padding: 0px;
    @include media-breakpoint-up(lg) {
      position: absolute!important;
      box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.65);
      border-radius: 15px;
      overflow: visible;
      &.show ul.dropdown-menu.show {
        left: 100%!important;
        top: 0px!important;
      }
    }
    .nav-item {
      border-bottom: 1px solid $light;
      padding: 0px;
      .dropdown-item {
        color: #fff;
        font-size: 14px;
        text-transform: uppercase;
        padding: 1rem 1.5rem;
        transition: all .2s ease-in;
        font-weight:$medium-weight;
        letter-spacing: 0px;
        &:hover {
          color: #fff!important;
          background: transparent!important;
          letter-spacing: .1em;
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
    .dropdown .dropdown-toggle::after {
      position: absolute;
      top: 43%;
      right: 15px;
      -webkit-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
  }
  @include media-breakpoint-down(lg) {
    .navbar-collapse {
      .navbar-nav {
        text-align: center;
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 1.1em;
        .nav-item {
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }
      .dropdown-menu {
        text-align: center;
        color: $brand-secondary!important;
        box-shadow: none;
        .dropdown .dropdown-toggle::after {
          position: absolute;
          top: 20px;
          right: 15px;
          transform: rotate(0deg);
        }
      }
    }
  }
}
header.sticky-top.scrolling .header-style-1-nav.navbar-light {
  .theme-logo {
    max-height: 85px;
    transition: none;
    @include media-breakpoint-up(md) {
      max-height: 115px;
    }
  }
  .navbar-toggler {
    @include media-breakpoint-up(md) {
      top: 32px;
    }
  }
}
.header-top .sub-heading {
  @include media-breakpoint-down(xs) {
    font-size: 12px;
  }
}
// Off Canvas
.header-style-1-offcanvas {
  .offcanvas-header {
    background: $brand-primary;
    color: white;
  }
}
h5#offcanvasExampleLabel {
  color: #fff;
}